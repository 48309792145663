import React from 'react';
import { Button, Divider } from 'antd';
import styled from 'styled-components';
import { LeftCircleOutlinedIcon, RightCircleOutlinedIcon } from 'components/Icons';

const ButtonGroup = Button.Group;

const PaginationButton = styled(Button)`
    font-size: 28px;
    color: ${(props) => props.theme.colors.primary}!important;
`;

const StyledDivider = styled(Divider)`
    color: ${(props) => props.theme.colors.primary};
    border-top-color: ${(props) => props.theme.colors.primary}!important;
`;

interface Props {
    slides: unknown[];
    currentSlide: number;
    goPrevious: () => void;
    goNext: () => void;
    fontSize?: number;
}

const CarouselPagination = ({
    slides,
    currentSlide,
    goPrevious,
    goNext,
    fontSize = 26
}: Props): JSX.Element => {
    if (slides.length > 1) {
        return (
            <StyledDivider orientation="right">
                <ButtonGroup
                    style={{
                        background: 'white'
                    }}
                >
                    <PaginationButton
                        type="link"
                        aria-label="previous"
                        disabled={currentSlide <= 0}
                        icon={<LeftCircleOutlinedIcon style={{ fontSize }} />}
                        onClick={() => {
                            goPrevious();
                        }}
                    />

                    <PaginationButton
                        type="link"
                        aria-label="next"
                        disabled={currentSlide >= slides.length - 1}
                        icon={<RightCircleOutlinedIcon style={{ fontSize }} />}
                        onClick={() => {
                            goNext();
                        }}
                    />
                </ButtonGroup>
            </StyledDivider>
        );
    }

    return <></>;
};

export default CarouselPagination;
